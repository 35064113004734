export const BACKEND_URL = process.env.REACT_APP_API_BASE_URL || 'https://api-pos-legacy.sanoft.com/api';
// export const BACKEND_URL = 'https://yscloud.in/backend/api';
// export const BACKEND_URL = 'http://127.0.0.1:8000/api';

export const STORAGE_KEYS = {
  TOKEN: 'x-auth-token',
  USER_ID: 'x-auth-user-id',
  FIRST_NAME: 'x-first-name',
  LAST_NAME: 'x-last-name',
  USERNAME: 'x-username',
};

export const HEADERS = {
  AUTH: 'Authorization',
};

export const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  ANY: '/',
  CONSOLE: '/console',

  SHOPS: '/console/shops',
  NEW_SHOP: '/console/new-shop',
  EDIT_SHOP: '/console/shops/:shopId',
  REGISTRATION_KEY: '/console/registration-keys',
  NEW_REGISTRATION_KEY: '/console/registration-keys/new-key',
  EDIT_REGISTRATION_KEY: '/console/registration-keys/:keyId',
  CREATE_TERMINAL: '/console/shops/:shopId/terminal',
  PAGE_404: '/page-404',
};

export const GST_PREFERENCES = [
  { name: 'Non GST', id: 0 },
  { name: 'Excluding GST', id: 1 },
  { name: 'Including GST', id: 2 },
];

export const SUBSCRIPTIONS = {
  1: 'Sanoft Lite',
  26: 'Sanoft Pro',
  27: 'Sanoft Elite',
  4: 'Standard',
  2: 'Premium',
  3: 'Ultimate',
  18: 'Standart ECS',
  19: 'Premium ECS',
};

export const SUBSCRIPTION_KEYS = {
  1: 'sanoft-lite',
  26: 'sanoft-pro',
  27: 'sanoft-elite',
  4: 'standard',
  2: 'premium',
  3: 'ultimate',
  18: 'standard_ecs',
  19: 'premium_ecs',
};

export const SUBSCRIPTION_IDS = {
  'sanoft-lite': 1,
  'sanoft-pro': 26,
  'sanoft-elite': 27,
  standard: 4,
  premium: 2,
  ultimate: 3,
  standard_ecs: 8,
  premium_ecs: 7,
};

export const DEALERS = {
  1: 'Scangle',
  2: 'Weighvox Dubai',
  3: 'Weighvox Oman',
  4: 'Sanoft',
  5: 'ALT',
  6: 'Dynamic',
  7: 'Saudi',
  8: 'Trass India',
  9: 'Avoquick',
  10: 'Yesweigh',
  11: 'Kraus',
  12: 'Elmass',
  13: 'HMS',
  14: 'D Sonic',
  15: 'Venkatachalam',
  16: 'Victory',
};
export const CURRENCIES = ['₹', 'RO', 'AED', 'SAR'];

export const TERMINAL_TYPES = { pos_terminal: 'POS', ecs_terminal: 'ECS' };

export const ADDITIONAL_SALES = [
  { key: 'additional_sales_3000', price: 300, count: 3000 },
  { key: 'additional_sales_6000', price: 500, count: 6000 },
];

export const MONTHLY_SUB_PERIOD = 30;
export const YEARLY_SUB_PERIOD = 365;
